import React, { useEffect } from 'react';
import classNames from 'classnames';
// import Helmet from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import StoreProvider from '../provider/StoreProvider';

import Header from '../components/Header';
import OverlayTransition from '../components/OverlayTransition';
import Footer from '../components/Footer';
import CartFlyout from '../components/CartFlyout';
import Newsletter from '../components/Newsletter';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

gsap.registerPlugin(ScrollTrigger);

const nsBase = 'layout';
const ns = `${ nsBase }-default`;

const Layout = ({ children, location }) => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true,
	});

	useEffect(() => {
		setTimeout(() => {
			ScrollTrigger.refresh();
		}, 1000);
	}, [location.pathname]);

	return (
		<StoreProvider>
			<div className={rootClassnames}>
				{/* {!location.pathname.includes('/mobile-insights') && (
					<Helmet>
						<script id={'ze-snippet'} src={'https://static.zdassets.com/ekr/snippet.js?key=973e38fb-6eec-4be8-b276-2edb69b9c70b'} />
					</Helmet>
				)} */}
				<div className={`${ ns }__header`}>
					<Header />
				</div>
				<div className={`${ ns }__content`}>
					{children}
				</div>
				<div className={`${ ns }__footer`}>
					<Newsletter
						heading={'Let’s stay connected.'}
						subheading={'Sign up to receive our latest news and exclusive offers.'}
					/>
					<Footer />
				</div>
				<CartFlyout />
				<OverlayTransition />
			</div>
		</StoreProvider>
	);
};

export default Layout;
