import React, { useContext } from 'react';
import classNames from 'classnames';

import StoreContext from '../../context/StoreContext';
import Button from '../Button';
import LineItem from '../CartFlyOutLineItem';
import XIcon from '../../icons/x-icon';

const nsBase = 'component';
const ns = `${ nsBase }-cart-flyout`;

const Cart = () => {
	const {
		products,
		store: { checkout },
		cartFlyoutActive,
		toggleCartFlyout,
	} = useContext(StoreContext);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`active`]: cartFlyoutActive,
	});

	const handleCheckout = () => {
		window.open(checkout.webUrl);
	};

	const lineItems = checkout.lineItems.map(item => {
		let currentProduct = {};
		Object.keys(products).forEach(key => {
			if (products[key].title === item.title) {
				currentProduct = products[key];
			}
		});
		return <LineItem key={item.id} item={item} product={currentProduct} />;
	});

	return (
		<div className={`${ rootClassnames }`}>
			<div className={`${ ns }__close`}>
				<div className={'h5'}>Your Cart</div>
				<button
					onClick={toggleCartFlyout}
					aria-label={'Close Cart'}
				>
					<XIcon />
				</button>
			</div>
			<div className={`${ ns }__line-items`}>
				{
					checkout.lineItems.length > 0
						? lineItems
						: <p className={`${ ns }__empty`}>You have no items in your cart.</p>
				}
			</div>
			<div className={`${ ns }__totals`}>
				{checkout.lineItems.length > 0 && (
					<>
						<div className={`${ ns }__totals-subtotal`}>
							<p>Subtotal</p>
							<p>{`$ ${ checkout.subtotalPrice }`}</p>
						</div>
						<div className={`${ ns }__totals-subtotal`}>
							<p>Taxes</p>
							<p>{`$ ${ checkout.totalTax }`}</p>
						</div>
						<div className={`${ ns }__totals-total`}>
							<p>Estimated Total</p>
							<p>{`$ ${ checkout.totalPrice }`}</p>
						</div>
						<Button
							className={`${ ns }__checkout btn`}
							onClick={handleCheckout}
							disabled={checkout.lineItems.length === 0}
						>
							Checkout
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default Cart;
