/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';

const nsBase = 'component';
const ns = `${ nsBase }-image`;

const Image = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		altText,
		src,
		localFile,
		mainImageMobile,
		tabletImage,
		laptopImage,
		desktopImage,
		widescreenImage,
		dataMedia,
	} = props;

	// if no mainImageMobile or src return nothing
	if (!mainImageMobile && !src) return null;

	let tabletSet = null;
	let laptopSet = null;
	let desktopSet = null;
	let widescreenSet = null;

	const getSrc = src => {
		return src.src ? src.src : src;
	};

	if (tabletImage && tabletImage.src) {
		tabletSet = <source media={`(min-width: 768px)`} srcSet={getSrc(tabletImage)} />;
	}

	if (laptopImage && laptopImage.src) {
		laptopSet = <source media={`(min-width: 992px)`} srcSet={getSrc(laptopImage)} />;
	}

	if (desktopImage && desktopImage.src) {
		desktopSet = <source media={`(min-width: 1200px)`} srcSet={getSrc(desktopImage)} />;
	}

	if (widescreenImage && widescreenImage.src) {
		widescreenSet = <source media={`(min-width: 1400px)`} srcSet={getSrc(widescreenImage)} />;
	}

	return (
		<picture>
			{widescreenSet}
			{desktopSet}
			{laptopSet}
			{tabletSet}
			<img
				className={rootClassnames}
				alt={
					altText
					|| localFile?.name
				}
				src={getSrc(mainImageMobile || src)}
				data-media={dataMedia}
			/>
		</picture>
	);
};

export default Image;
