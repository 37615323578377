import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';

import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../functions';
import { animationExit, animationEntry } from '../../functions/pageTransitions';

/*
- href prop wll create a regular a-tag but still with button classes
- disabled prop will add 'tabindex' of -1 and 'aria-disabled' of 'true' for accessibility
- variant - prop will add a modifier to classname
*/

const nsBase = 'component';
const ns = `${ nsBase }-button`;

const Button = React.forwardRef(
	(
		{
			active,
			className,
			variant,
			type,
			as,
			to,
			href,
			direction,
			...props
		},
		ref
	) => {
		// define button classes
		const rootClassnames = classNames(
			className,
			`${ ns } btn`,
			`${ ns }--${ variant }`,
			active && 'active'
		);

		if (href || isExternalUrl(to)) {
			return (
				<SafeAnchor
					{...props} // eslint-disable-line
					as={as}
					href={href || to}
					ref={ref}
					target={'_blank'}
					className={classNames(rootClassnames, props.disabled && 'disabled')}
				/>
			);
		}

		if (ref) {
			props.ref = ref; // eslint-disable-line
		}

		if (!as) {
			props.type = type; // eslint-disable-line
		}

		if (to && direction) {
			return (
				<TransitionLink
					{...props} // eslint-disable-line
					disabled={props.disabled}
					to={to || '/'}
					ref={ref}
					className={rootClassnames}
					exit={{
						length: 1,
						trigger: ({ exit, node }) => {
							animationExit(exit, direction, node);
						},
						state: { test: 'exit state' },
					}}
					entry={{
						delay: 1.25,
						trigger: ({ entry }) => { animationEntry(entry, direction); },
					}}
				>
					{props.children}
				</TransitionLink>
			);
		}

		if (to) {
			return (
				<GatsbyLink
					{...props} // eslint-disable-line
					to={to || '/'}
					ref={ref}
					className={rootClassnames}
				/>
			);
		}

		const Component = as || 'button';
		return <Component {...props} className={rootClassnames} />; // eslint-disable-line
	}
);

Button.defaultProps = {
	variant: 'default',
	active: false,
	disabled: false,
	type: 'button',
	direction: false,
};

export default Button;
