import React from 'react';
import classNames from 'classnames';

import Link from '../Link';

const violuxLogo = '/assets/images/violux-logo.png';
const violoxIcon = '/assets/images/violux-logo-icon.png';

const nsBase = 'component';

const ns = `${ nsBase }-logo`;

const Logo = props => {
	const {
		variant,
		href,
		handleMenu,
		hide,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ variant }`]: true,
		[`hide`]: hide,
	});

	const handleLogoClick = () => {
		if (handleMenu) {
			handleMenu();
		}
	};

	return (
		<div className={rootClassnames}>
			<Link
				to={href}
				aria-label={`Logo`}
				onClick={handleLogoClick}
			>
				<img className={`${ ns }__logo-img`} src={violuxLogo} alt={'Violux Logo'} />
				<img className={`${ ns }__icon-img`} src={violoxIcon} alt={'Violux Icon'} />
			</Link>
		</div>
	);
};

Logo.defaultProps = {
	variant: 'default',
	href: '/',
};

export default Logo;
