import React from 'react';
import classNames from 'classnames';

const nsBase = 'component';
const ns = `${ nsBase }-banner`;

const Banner = React.forwardRef((props, ref) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const bannerText = 'Luma Pro is expected to ship in January 2021';

	return (
		<div ref={ref} className={`${ rootClassnames } container-fluid`}>
			<div className={'h6'}>
				{bannerText}
			</div>
		</div>
	);
});

export default Banner;
