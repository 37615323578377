import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';

import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../functions';
import { animationExit, animationEntry } from '../../functions/pageTransitions';

const nsBase = 'component';
const ns = `${ nsBase }-link`;

const Link = React.forwardRef(
	(
		{
			className,
			disabled,
			variant,
			to,
			href,
			direction,
			...props
		},
		ref
	) => {
		const rootClassnames = classNames({
			[`${ ns }`]: true,
			[`${ ns }--${ variant }`]: variant,
			[className]: className,
		});

		if (href || isExternalUrl(to)) {
			return (
				<SafeAnchor
					{...props} // eslint-disable-line
					disabled={disabled}
					href={href || to}
					ref={ref}
					target={'_blank'}
					className={classNames(rootClassnames)}
				/>
			);
		}

		if (disabled) {
			props.tabIndex = -1; // eslint-disable-line
			props['aria-disabled'] = true; // eslint-disable-line
		}

		if (direction) {
			return (
				<TransitionLink
					{...props} // eslint-disable-line
					disabled={disabled}
					to={to || '/'}
					ref={ref}
					className={rootClassnames}
					exit={{
						length: 1,
						trigger: ({ exit, node }) => {
							animationExit(exit, direction, node);
						},
						state: { test: 'exit state' },
					}}
					entry={{
						delay: 1.25,
						trigger: ({ entry }) => { animationEntry(entry, direction); },
					}}
				>
					{props.children}
				</TransitionLink>
			);
		}

		return (
			<GatsbyLink
				{...props} // eslint-disable-line
				disabled={disabled}
				to={to || '/'}
				ref={ref}
				className={rootClassnames}
			/>
		);
	}
);

Link.defaultProps = {
	disabled: false,
	direction: false,
};

export default Link;
