import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';

import StoreContext from '../../context/StoreContext';

import Link from '../Link';

const nsBase = 'component';
const ns = `${ nsBase }-line-item`;

const LineItem = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const { item, product } = props;
	const {
		updateLineItem,
		removeLineItem,
		store: { client, checkout },
	} = useContext(StoreContext);

	const [quantity, setQuantity] = useState(item.quantity);

	const variantImage = item.variant.image ? (
		<img
			className={`${ ns }__image`}
			src={item.variant.image.src}
			alt={`${ item.title } product shot`}
		/>
	) : null;

	const handleRemove = () => {
		removeLineItem(client, checkout.id, item.id);
	};

	const handleQuantityChange = type => {
		const currentQuantity = quantity;

		if (type === 'dec' && currentQuantity !== 0) {
			const updatedQuantity = currentQuantity - 1;
			setQuantity(updatedQuantity);
			updateLineItem(client, checkout.id, item.id, updatedQuantity);
		} else if (type === 'inc') {
			const updatedQuantity = currentQuantity + 1;
			setQuantity(updatedQuantity);
			updateLineItem(client, checkout.id, item.id, updatedQuantity);
		}
	};

	useEffect(() => {
		setQuantity(item.quantity);
	}, [item]);

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__main-wrapper`}>
				<Link to={`/product/${ item.variant.product.handle }/`}>
					{variantImage}
				</Link>
				<div className={`${ ns }__main-info`}>
					<div>
						<p className={`${ ns }__title`}>
							{item.title}
						</p>
						<p className={`${ ns }__subtitle`}>
							{product.title === 'Luma Pro' ? '' : product.subtitle}
							{/* {
								item.variant.title === !'Default Title'
									? item.variant.title
									: ''
							} */}
						</p>
					</div>
					<p className={`${ ns }__price`}>
						{item.variant.compareAtPrice && <span className={`sale`}>{`$${ item.variant.compareAtPrice }`}</span>}
						{`$${ item.variant.price }`}
					</p>
				</div>
			</div>
			<div className={`${ ns }__actions`}>
				<div className={`${ ns }__quantity`}>
					<button
						className={`${ ns }__quantity-decrease`}
						onClick={() => { handleQuantityChange('dec'); }}
					>
						-
					</button>
					<input
						className={`${ ns }__quantity-value`}
						id={'quantity'}
						name={'quantity'}
						readOnly
						value={quantity}
					/>
					<button
						className={`${ ns }__quantity-increase`}
						onClick={() => { handleQuantityChange('inc'); }}
					>
						+
					</button>
				</div>
				<button className={`${ ns }__remove`} onClick={handleRemove}>Remove</button>
			</div>
		</div>
	);
};

export default LineItem;
