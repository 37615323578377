import React from 'react';

const Cart = () => {
	return (
		<svg
			width={'27px'}
			height={'25px'}
			xmlns={'http://www.w3.org/2000/svg'}
			viewBox={'0 0 24 24'}
		>
			<path
				fill={'#58595B'}
				d={'M17.5 18a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM4 2a1 1 0 0 1 .97.757L7.78 14h9.44l1.689-6.757a.5.5 0 0 1 .606-.364l.97.242a.5.5 0 0 1 .364.607l-1.879 7.515A1 1 0 0 1 18 16H7a1 1 0 0 1-.97-.757L3.22 4H1.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z'}
			/>
		</svg>
	);
};

export default Cart;
