import React, {
	useState,
	useRef,
	useEffect,
	useContext
} from 'react';
import classNames from 'classnames';
import reduce from 'lodash/reduce';
import { useLocation } from '@reach/router';

import Logo from '../Logo';
import Link from '../Link';
import { useScrollDirection } from '../../hooks';
import Banner from '../Banner';
import NavDropdown from '../NavDropdown';
import CartIcon from '../../icons/cart';
import CartEmptyIcon from '../../icons/cart-empty';

import StoreContext from '../../context/StoreContext';
import ProductBanner from '../ProductBanner';

const SHOPIFY_ACCOUNT_URL = `https://shop.violux.com/account/login`;

export const menuItems = [
	{
		cssClasses: [],
		label: 'Products',
		target: null,
		url: null,
		subMenuItems: [
			{
				cssClasses: [],
				label: 'Luma Pro',
				target: null,
				url: '/product/luma-pro',
			},
			{
				cssClasses: [],
				label: 'Luma Pro+',
				target: null,
				url: '/product/luma-pro-plus',
			},
		],
	},
	{
		cssClasses: [],
		label: 'Technology',
		target: null,
		url: '/technology',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'Insights',
		target: null,
		url: '/insights',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'About',
		target: null,
		url: '/about',
		subMenuItems: [],
	},
];

/* eslint-disable */
const useQuantity = () => {
	const { store: {checkout} } = useContext(StoreContext)
	const items = checkout ? checkout.lineItems : []
	const total = reduce(items, (acc, item) => acc + item.quantity, 0)
	return [total !== 0, total]
}

const nsBase = 'component';
const ns = `${ nsBase }-header`;

const Header = () => {
	const [showNavDropdown, setShowNavDropdown] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [subMenuOpen, setSubMenuOpen] = useState(true);
	const scrollDirection = useScrollDirection();
	const bannerRef = useRef();
	const { pathname } = useLocation();
	const showBanner = false;
	const [stickyNav, setStickyNav] = useState(!showBanner);
	const [hasItems, quantity] = useQuantity();

	const { toggleCartFlyout } = useContext(StoreContext)

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`menu-open`]: menuOpen,
		[`hide`]: scrollDirection === 'down' && !showNavDropdown && !menuOpen && stickyNav,
		[`sticky-nav`]: stickyNav,
		[`pdp`]: pathname.includes('product'),
	});

	const handleMenuToggle = () => {
		(window.innerWidth < 992 && setMenuOpen(!menuOpen));
	};

	// if on desktop, it triggers the NavDropdownComponent
	const handleSubMenuToggle = () => {
		window.innerWidth < 992
			? setSubMenuOpen(!subMenuOpen)
			: setShowNavDropdown(!showNavDropdown);
	};

	const handleShopifyRedirect = (e) => {
		e.preventDefault();

		const checkoutIdHashBase64 = localStorage.getItem('shopify_checkout_id');

		if (checkoutIdHashBase64) {
			// decodes the base64 hash and grabs the checkout id from that string
			const decodedCheckoutIdHash = atob(checkoutIdHashBase64);
			const checkoutId = decodedCheckoutIdHash.split('Checkout/')[1].split('?')[0];

			window.location.replace(`${SHOPIFY_ACCOUNT_URL}?checkoutId=${checkoutId}`);
		} else {
			window.location.replace(`${SHOPIFY_ACCOUNT_URL}`);
		}
	}

	const renderSubMenuItems = menuItem => {
		const activeSubMenuClass = subMenuOpen ? `${ ns }__nav-submenu open` : `${ ns }__nav-submenu`;
		const submenuClassName = classNames({
			[`${ ns }__nav-menu-item--button`]: true,
			[`active`]: pathname.includes('product'),
		});
		return (
			<>
				<button
					className={submenuClassName}
					onClick={handleSubMenuToggle}
					data-toggle-dropdown
				>
					Products
				</button>
				<ul className={activeSubMenuClass}>
					{menuItem.subMenuItems.map(item => {
						return (
							<li className={`${ ns }__nav-submenu--item`} key={item.url}>
								<Link
									to={item.url}
									onClick={handleMenuToggle}
									activeClassName={'active'}
								>
									{item.label}
								</Link>
							</li>
						);
					})}
				</ul>
			</>
		);
	};

	const renderMenuItems = menuItems => {
		return (
			<div
				className={`${ ns }__nav-menu`}
			>
				<div className={`${ ns }__main-links`}>
					{menuItems.map(link => {
						return (
							<div
								key={link.label}
								className={`${ ns }__nav-menu-item`}
							>
								{
									link.url ? (
										<Link
											to={link.url}
											onClick={() => { handleMenuToggle(); }}
											activeClassName={'active'}
										>
											{link.label}
										</Link>
									) : renderSubMenuItems(link)
								}
							</div>
						);
					})}
					<div className={`${ ns }__nav-menu-item d-block d-md-none`}>
						<Link
							onClick={handleShopifyRedirect}
							className={`${ ns }__nav-account`}
							href={`${SHOPIFY_ACCOUNT_URL}`}
						>
							Account
						</Link>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		const onScroll = () => {
			if (bannerRef && window.scrollY > bannerRef.current.offsetHeight) {
				setStickyNav(true);
			} else {
				setStickyNav(false);
			}
		};

		if (showBanner) {
			window.addEventListener('scroll', () => { onScroll(); });
		}

		return window.removeEventListener('scroll', () => { onScroll(); });
	}, [showBanner]);

	return (
		<div className={`${ rootClassnames }`}>
			{showBanner && (
				<Banner
					ref={bannerRef}
				/>
			)}
			<div className={`${ ns }__container`}>
				<div className={`${ ns }__header`}>
					<header
						className={`${ ns }__content container-fluid`}
					>
						<div
							className={`${ ns }__logo`}
						>
							<Logo
								hide={scrollDirection === 'down'}
								handleMenu={() => { (menuOpen && handleMenuToggle()); }}
							/>
						</div>
						<button
							type={'button'}
							aria-label={'Open navigational menu'}
							className={`${ ns }__menu-toggle`}
							onClick={() => { handleMenuToggle(); }}
						>
							<div />
							<div />
						</button>
						{renderMenuItems(menuItems)}
						<div className={`${ ns }__secondary-links`}>
							<div className={`${ ns }__nav-menu-item`}>
								<Link
									className={`${ ns }__nav-support`}
									href={'https://violux.zendesk.com/hc/en-us'}
								>
									Support
								</Link>
							</div>
							<div className={`${ ns }__nav-menu-item`}>
								<Link
									onClick={handleShopifyRedirect}
									className={`${ ns }__nav-account`}
									href={`${SHOPIFY_ACCOUNT_URL}`}
								>
									Account
								</Link>
							</div>
							<button
								className={`${ ns }__nav-cart-icon ${ hasItems ? 'has-items' : ''}`}
								aria-label={'Cart'}
								onClick={toggleCartFlyout}
							>
								{hasItems ? (
									<>
										<CartIcon />
										<span>
											{quantity}
										</span>
									</>
								) : (
									<CartEmptyIcon />
								)}
							</button>
						</div>
					</header>
					{!showNavDropdown && pathname.includes('product') && (
						<ProductBanner
							handleAddToCart={() => { return; }}
						/>
					)}
				</div>
				<NavDropdown
					showNavDropdown={showNavDropdown}
					setShowNavDropdown={() => { setShowNavDropdown(); }}
				/>
			</div>
		</div>
	);
};

export default Header;
