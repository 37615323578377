/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy'

import Context from '../context/StoreContext'

const client = Client.buildClient({
	storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
	domain: `shop.violux.com`,
})

const products = {
	lumaPro: {
			id: 1,
			title: 'Luma Pro',
			slug: 'luma-pro',
			link: '/product/luma-pro',
			subtitle: 'Smart UV-C Light Disinfection',
			price: '$499',
			comingSoon: 'true',
			salePrice: '',
			description: '',
			image: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/product/Luma_straight.png',
			},
			imageTextStacked: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/pdp/more_space_luma.jpg',
			},
			imageTilted: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/product/Luma.png',
			},
			pdpLastModule: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/pdp/PDPLumaPro_LastModule.jpg',
			},
			infoSnippet: {
				lamps: '4',
				UVCpower: '32w',
				floor: 'Stainless Steel Grate',
				cleanCycle: '60 Second',
				warranty: '1 Year',
			},
			info: [
				'60 Second Cleaning Cycle',
				'Stainless Steel Grate Floor',
				'1 Year Warranty',
			],
			lampCount: '4',
			watts: '32w',
			cleaningTime: '60 Seconds',
			threeTextUpCopy: 'Safely disinfect your daily used, high-touch objects in as little as 60 seconds.',
			technicalSpecs: {
				size: '16” W x 16” H x 19” L',
				capacity: '14.5” W x 12.4” H x 12.3” L (2,210 cubic inches)',
				weight: '34.4 lbs',
				powerInput: '85-305 VAC, 50-60Hz',
				uvcLamp: 'UV-C 254nm',
				lamps: '4 - 8w UV-C Light Lamps for Germicidal Treatment (Ozone-free)',
				wifiEnabled: '2.4 GHz',
				appEnabled: 'iOS & Android',
			},
			faqItems: [
				{
					question: 'How long does Luma Pro take to sanitize my object?',
					answer: 'Just 60 seconds! Run your smaller items through one 180-second cycle, or multiple larger items through two 180-second cycles for optimal cleaning.',
				},
				{
					question: 'What is UV-C light?',
					answer: 'UV-C defines the ultraviolet radiation within the wavelength range of 280 to 100 nanometers. Most people have heard of the dangers of UV-A (400 - 315 nm), and UV-B (315 - 280 nm) exposure from the sun. UV-C is also produced by the sun, but it is the shortest of the ultraviolet wavelengths and is almost entirely filtered out by the atmosphere before reaching the earth’s surface. Therefore, people have little or no natural exposure to it, and that’s a good thing.',
				},
				{
					question: 'Is UV-C light effective at killing bacteria and viruses?',
					answer: 'Artificially produced UV-C has been used successfully as a germicide and bactericide for decades. It has been proven to kill the growth of micro-organisms and pathogens like bacteria and viruses and provides a chemical-free alternative to other disinfection methods.',
				},
				{
					question: 'Are UV-C light sanitization devices safe?',
					answer: 'Yes, when used properly in a safe and controlled environment. Our device is a sealed system that will automatically shut-off if a user accidentally or intentionally opens the door while a disinfection cycle is operating preventing accidental exposure directly to the UV-C lights. Even though you can see when the lights are on during a cycle, our protective glass door blocks all UV-C light from escaping from the device and is not harmful to look at.',
				},
				{
					question: 'What can I disinfect in my device?',
					answer: 'Anything that fits inside your device can be disinfected. Our device is designed to quickly and safely disinfect items like phones, tablets, keys, wallets, remote controls, glasses, make-up, jewelry, gaming controllers, and baby toys to name a few. Use the optional metal shelf to disinfect multiple smaller objects at the same time or remove the shelf for larger items. Ensure your items are properly spaced out and positioned corrected as only areas exposed to UV-C light will be disinfected; items touching or overlapping may not receive optimal UV-C light exposure.',
				},
				{
					question: 'Are the lamps replaceable?',
					answer: 'Yes, the lamps are replaceable and the mobile companion app will notify you when a lamp burns out. You can order replacement lamps on violux.com',
				},
				{
					question: 'Do you offer a warranty?',
					answer: 'Yes, we offer a one-year warranty after purchase, free from defects in material and workmanship and in material compliance with Violux’s then current specifications, documentation, and published materials. See our warranty information for more details.',
				},
				{
					question: 'What are the differences between Luma Pro and Luma Pro+',
					answer: 'Luma Pro has a 60 second cleaning cycle, stainless steel grate floor, and 1 year warranty. Luma Pro+ has a 30 second cleaning cycle, optical quartz glass floor, and 2 year warranty.',
				},
			]
		},
	lumaProPlus: {
			id: 2,
			title: 'Luma Pro+',
			slug: 'luma-pro-plus',
			link: '/product/luma-pro-plus',
			subtitle: 'Smart UV-C Light Disinfection',
			price: '$799',
			salePrice: '',
			description: '',
			image: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/product/Luma_Pro_straight.png',
			},
			imageTextStacked: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/pdp/more_space_luma_pro.jpg',
			},
			imageTilted: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/product/Luma_Pro.png',
			},
			pdpLastModule: {
				altText: 'Product shot',
				mainImageMobile: '/assets/images/pdp/PDPLumaPro_LastModule.jpg',
			},
			infoSnippet: {
				lamps: '4',
				UVCpower: '32w',
				floor: 'Optical Quartz Glass',
				cleanCycle: '30 Second',
				warranty: '2 Year',
			},
			info: [
				'30 Second Cleaning Cycle',
				'Optical Quartz Glass Floor',
				'2 Year Warranty',
			],
			lampCount: '4',
			watts: '32w',
			cleaningTime: '30 Seconds',
			threeTextUpCopy: 'Professionally clean everyday objects in as little as 30 seconds.',
			technicalSpecs: {
				size: '16” W x 16” H x 19” L',
				capacity: '14.5” W x 12.4” H x 12.3” L (2,210 cubic inches)',
				weight: '32.5 lbs',
				powerInput: '85-305 VAC, 50-60Hz',
				uvcLamp: 'UV-C 254nm',
				lamps: '4 - 8w UV-C Light Lamps for Germicidal Treatment (Ozone-free)',
				wifiEnabled: '2.4 GHz',
				appEnabled: 'iOS & Android',
			},
			faqItems: [
				{
					question: 'How long does Luma Pro+ take to sanitize my object?',
					answer: 'Just 30 seconds! Run your smaller items through one 60-second cycle, or multiple larger items through two 60-second cycles for optimal cleaning.',
				},
				{
					question: 'What is UV-C light?',
					answer: 'UV-C defines the ultraviolet radiation within the wavelength range of 280 to 100 nanometers. Most people have heard of the dangers of UV-A (400 - 315 nm), and UV-B (315 - 280 nm) exposure from the sun. UV-C is also produced by the sun, but it is the shortest of the ultraviolet wavelengths and is almost entirely filtered out by the atmosphere before reaching the earth’s surface. Therefore, people have little or no natural exposure to it, and that’s a good thing.',
				},
				{
					question: 'Is UV-C light effective at killing bacteria and viruses?',
					answer: 'Artificially produced UV-C has been used successfully as a germicide and bactericide for decades. It has been proven to kill the growth of micro-organisms and pathogens like bacteria and viruses and provides a chemical-free alternative to other disinfection methods.',
				},
				{
					question: 'Are UV-C light sanitization devices safe?',
					answer: 'Yes, when used properly in a safe and controlled environment. Our device is a sealed system that will automatically shut-off if a user accidentally or intentionally opens the door while a disinfection cycle is operating preventing accidental exposure directly to the UV-C lights. Even though you can see when the lights are on during a cycle, our protective glass door blocks all UV-C light from escaping from the device and is not harmful to look at.',
				},
				{
					question: 'What can I disinfect in my device?',
					answer: 'Anything that fits inside your device can be disinfected. Our device is designed to quickly and safely disinfect items like phones, tablets, keys, wallets, remote controls, glasses, make-up, jewelry, gaming controllers, and baby toys to name a few. Use the optional metal shelf to disinfect multiple smaller objects at the same time or remove the shelf for larger items. Ensure your items are properly spaced out and positioned corrected as only areas exposed to UV-C light will be disinfected; items touching or overlapping may not receive optimal UV-C light exposure.',
				},
				{
					question: 'Are the lamps replaceable?',
					answer: 'Yes, the lamps are replaceable and the mobile companion app will notify you when a lamp burns out. You can order replacement lamps on violux.com',
				},
				{
					question: 'Do you offer a warranty?',
					answer: 'Yes, we offer a two-year warranty after purchase, free from defects in material and workmanship and in material compliance with Violux’s then current specifications, documentation, and published materials. See our warranty information for more details.',
				},
				{
					question: 'What are the differences between Luma Pro and Luma Pro+',
					answer: 'Luma Pro has a 60 second cleaning cycle, stainless steel grate floor, and 1 year warranty. Luma Pro+ has a 30 second cleaning cycle, optical quartz glass floor, and 2 year warranty.',
				},
			]
		},
};

const ContextProvider = ({ children }) => {
	let initialStoreState = {
		client,
		adding: false,
		checkout: { lineItems: [] },
		products: [],
		shop: {},
	}

	const [store, updateStore] = useState(initialStoreState)
	const [lumaProducts, setLumaProducts] = useState(null);
	const [currentLumaProduct, setCurrentLumaProduct] = useState(null);
	const [cartFlyoutActive, setCartFlyoutActive] = useState(false);
	let isRemoved = false

	const toggleCartFlyout = (delay = 0) => {
		setTimeout(() => {
			if (cartFlyoutActive) {
				document.body.style.overflow = 'unset';
			} else {
				document.body.style.overflow = 'hidden';
			}
			setCartFlyoutActive(!cartFlyoutActive);
		}, delay);
	};

	useEffect(() => {
		const initializeCheckout = async () => {
			// Check for an existing cart.
			const isBrowser = typeof window !== 'undefined'
			const existingCheckoutID = isBrowser
				? localStorage.getItem('shopify_checkout_id')
				: null

			const setCheckoutInState = checkout => {
				if (isBrowser) {
					localStorage.setItem('shopify_checkout_id', checkout.id)
				}

				updateStore(prevState => {
					return { ...prevState, checkout }
				})
			}

			const createNewCheckout = () => store.client.checkout.create()
			const fetchCheckout = id => store.client.checkout.fetch(id)

			if (existingCheckoutID) {
				try {
					const checkout = await fetchCheckout(existingCheckoutID)
					// Make sure this cart hasn’t already been purchased.
					if (!isRemoved && !checkout.completedAt) {
						setCheckoutInState(checkout)
						return
					}
				} catch (e) {
					localStorage.setItem('shopify_checkout_id', null)
				}
			}

			const newCheckout = await createNewCheckout()
			if (!isRemoved) {
				setCheckoutInState(newCheckout)
			}
		}

		initializeCheckout()
	}, [store.client.checkout])

	useEffect(() => () => { isRemoved = true; }, [])

	return (
		<Context.Provider
			value={{
				toggleCartFlyout,
				cartFlyoutActive,
				products,
				store,
				lumaProducts,
				setLumaProducts,
				currentLumaProduct,
				setCurrentLumaProduct,
				addVariantToCart: (variantId, quantity) => {
					if (variantId === '' || !quantity) {
						console.error('Both a size and quantity are required.')
						return
					}

					updateStore(prevState => {
						return { ...prevState, adding: true }
					})

					const { checkout, client } = store

					const checkoutId = checkout.id
					const lineItemsToUpdate = [
						{ variantId, quantity: parseInt(quantity, 10) },
					]

					return client.checkout
						.addLineItems(checkoutId, lineItemsToUpdate)
						.then(checkout => {
							updateStore(prevState => {
								return { ...prevState, checkout, adding: false }
							})
						})
				},
				removeLineItem: (client, checkoutID, lineItemID) => {
					return client.checkout
						.removeLineItems(checkoutID, [lineItemID])
						.then(res => {
							updateStore(prevState => {
								return { ...prevState, checkout: res }
							})
						})
				},
				updateLineItem: (client, checkoutID, lineItemID, quantity) => {
					const lineItemsToUpdate = [
						{ id: lineItemID, quantity: parseInt(quantity, 10) },
					]

					return client.checkout
						.updateLineItems(checkoutID, lineItemsToUpdate)
						.then(res => {
							updateStore(prevState => {
								return { ...prevState, checkout: res }
							})
						})
				},
			}}
		>
			{children}
		</Context.Provider>
	)
}
export default ContextProvider