import gsap from 'gsap';
import CustomEase from './easing/CustomEase.min';

gsap.registerPlugin(CustomEase);
CustomEase.create('custom', 'M0,0 C0.2,0 0.5,0 0.5,0.5 0.5,1 0.8,1 1,1');

export const animationExit = (direction = 'right') => {
	const body = document.getElementsByTagName('body')[0];

	// overlay
	const overlay = document.getElementById('overlay');
	const overlayBackground = overlay.querySelectorAll('.component-overlay-transition__background');

	const directionFrom = direction === 'left' ? '-100%' : '100%';

	gsap.set(body, {
		overflow: 'hidden',
	});
	// set overlay transition direction
	gsap.set(overlay, {
		visibility: 'visible',
	});
	gsap.set(overlayBackground, {
		x: directionFrom,
		transformPerspective: 500,
	});
	gsap.to(overlayBackground, {
		duration: 1,
		x: '0%',
		ease: 'custom',
	});
};

export const animationEntry = (direction = 'right') => {
	const body = document.getElementsByTagName('body')[0];

	// overlay
	const overlay = document.getElementById('overlay');
	const overlayBackground = overlay.querySelectorAll('.component-overlay-transition__background');

	const directionTo = direction === 'left' ? '100%' : '-100%';

	gsap.to(overlayBackground, {
		duration: 1,
		x: directionTo,
		ease: 'custom',
	});
	gsap.to(overlay, {
		duration: 0,
		delay: 1,
		visibility: 'hidden',
	});
	gsap.set(body, {
		overflow: 'auto',
	});
};
