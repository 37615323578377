import React from 'react';

const XIcon = () => {
	return (
		<svg width={'22px'} height={'22px'} viewBox={'0 0 22 22'} version={'1.1'}>
			<g id={'MOBILE'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
				<g id={'Mobile_Menu-Copy'} transform={'translate(-333.000000, -40.000000)'} fill={'#338FF5'}>
					<g id={'Group'} transform={'translate(333.000000, 40.000000)'}>
						<polygon id={'Rectangle-4-Copy-2'} points={'1.80761184 0.393398282 21.6066017 20.1923882 20.1923882 21.6066017 0.393398282 1.80761184'} />
						<polygon id={'Rectangle-5-Copy'} points={'20.1923882 0.393398282 21.6066017 1.80761184 1.80761184 21.6066017 0.393398282 20.1923882'} />
					</g>
				</g>
			</g>
		</svg>
	);
};

export default XIcon;
