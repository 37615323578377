import React, { useState } from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';

const nsBase = 'component';
const ns = `${ nsBase }-newsletter`;

const Newsletter = ({ heading, subheading }) => {
	const [newsletterEmail, setNewsletterEmail] = useState('');
	const [newsletterError, setNewsletterError] = useState(false);
	const successMessage = `Thanks! You've signed up successfully.`;
	const errorMessage = `An error has occured, please refresh and try again.`;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const onChangeHandler = e => {
		setNewsletterEmail(e.target.value);
	};

	const submitForm = e => {
		e.preventDefault();

		const payload = { email: newsletterEmail };

		fetch(process.env.GATSBY_NEWSLETTER_API_URL, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		}).then(res => {
			return res.json();
		}).then(data => {
			// console.log(data);
			if (data.success === false) {
				setNewsletterError(true);
			} else {
				setNewsletterEmail(successMessage);
			}
		}).catch(error => {
			// eslint-disable-next-line no-console
			console.log(error);
			setNewsletterError(true);
		});
	};

	return (
		<div className={`${ rootClassnames } container-fluid`}>
			<div className={`${ ns }__content`}>
				<div className={`${ ns }__text-container`}>
					<div className={`${ ns }__heading h4`}>{ heading }</div>
					<p className={`${ ns }__subheading`}>{ subheading }</p>
				</div>
				<form
					action={''}
					className={`${ ns }__form`}
					onSubmit={e => { return submitForm(e); }}
				>
					<div className={`${ ns }__form-group`}>
						<label className={`${ ns }__label`} htmlFor={'newsletter-email'}>
							<input
								value={newsletterEmail}
								className={`${ ns }__input`}
								id={'newsletter-email'}
								type={'email'}
								placeholder={'Enter your email'}
								onChange={onChangeHandler}
							/>
						</label>
					</div>
					<Button className={`${ ns }__button`} type={'submit'} aria-label={'submit'}>Submit</Button>
				</form>
				{newsletterError && (
					<p className={`${ ns }__error`}>
						{ errorMessage }
					</p>
				)}
			</div>
		</div>
	);
};

export default Newsletter;
