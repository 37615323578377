import React from 'react';
import classNames from 'classnames';

import { menuItems } from '../Header/Header';
import Link from '../Link';
import Logo from '../Logo';
import SocialIcons from '../SocialIcons/SocialIcons';

const footerMainLinks = [
	{
		cssClasses: [],
		label: 'Technology',
		target: null,
		url: '/technology',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'Insights',
		target: null,
		url: '/insights',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'About',
		target: null,
		url: '/about',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'Press',
		target: null,
		url: '/press',
		subMenuItems: [],
	},
];

const footerSecondaryLinks = [
	{
		cssClasses: [],
		label: 'Support',
		target: null,
		url: 'https://violux.zendesk.com/hc/en-us',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'Contact Us',
		target: null,
		url: '/contact',
		subMenuItems: [],
	},
];

const socialLinks = [
	{
		icon: <SocialIcons icon={'facebook'} />,
		iconName: 'facebook',
		url: 'https://www.facebook.com/Violux-UV-100195638509639/',
	},
	{
		icon: <SocialIcons icon={'instagram'} />,
		iconName: 'instagram',
		url: 'https://www.instagram.com/Violux_UV/',
	},
	{
		icon: <SocialIcons icon={'twitter'} />,
		iconName: 'twitter',
		url: 'https://twitter.com/violux_uv',
	},
	{
		icon: <SocialIcons icon={'youtube'} />,
		iconName: 'youtube',
		url: 'https://www.youtube.com/channel/UCWXDUp4k51Soy6jfY5SurLQ',
	},
];

const legalLinks = [
	{
		cssClasses: [],
		label: 'Privacy Policy',
		target: null,
		url: '/privacy-policy',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'Terms of Use',
		target: null,
		url: '/terms-of-use',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'Warranty',
		target: null,
		url: '/warranty',
		subMenuItems: [],
	},
	{
		cssClasses: [],
		label: 'EULA',
		target: null,
		url: '/eula',
		subMenuItems: [],
	},
];

const nsBase = 'component';
const ns = `${ nsBase }-footer`;

const Footer = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const renderLinks = links => {
		return links.map(link => {
			return (
				<div className={`${ ns }__link`} key={link.url}>
					<Link
						to={link.url}
						variant={'blue'}
					>
						{link.label}
					</Link>
				</div>
			);
		});
	};

	const pdpLinks = menuItems[0].subMenuItems;

	return (
		<footer className={`${ rootClassnames } container-fluid`}>
			<div className={`${ ns }__nav-wrapper`}>
				<Logo />
				<nav className={`${ ns }__nav`}>
					<div className={`${ ns }__nav--pdp`}>
						{renderLinks(pdpLinks)}
					</div>
					<div className={`${ ns }__nav--pages`}>
						{renderLinks(footerMainLinks)}
					</div>
					<div className={`${ ns }__nav--pages`}>
						{renderLinks(footerSecondaryLinks)}
					</div>
				</nav>
			</div>
			<div className={`${ ns }__disclaimer`}>*Direct exposure to UV-C light is required to kill viruses and bacteria. Surface areas not directly exposed may not be fully disinfected.</div>
			<div className={`${ ns }__meta-wrapper`}>
				<div className={`${ ns }__socials`}>
					{socialLinks.map(social => {
						return (
							<Link
								key={social.url}
								className={`${ ns }__socials--icon`}
								href={social.url}
								target={`_blank`}
								title={social.iconName}
							>
								{social.icon}
							</Link>
						);
					})}
				</div>
				<div className={`${ ns }__legal-container`}>
					<div className={`${ ns }__legal-links`}>
						{renderLinks(legalLinks)}
					</div>
					<p className={`${ ns }__copyright`}>Copyright © 2020 Violux. All rights reserved. Designed and made in the USA.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
