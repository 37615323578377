/* eslint-disable */
import classNames from 'classnames';
import React, {useContext} from 'react';
import StoreContext from '../../context/StoreContext';
import { useScrollDirection } from '../../hooks';

import Button from '../Button';

const nsBase = 'component';
const ns = `${ nsBase }-product-banner`;

const ProductBanner = () => {
	const scrollDirection = useScrollDirection();

	const {
		currentLumaProduct: product,
		toggleCartFlyout,
		addVariantToCart,
		store: { client },
	} = useContext(StoreContext);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`sticky`]: scrollDirection === 'down',
	});

	const handleAddToCart = () => {
		const {
			variants: [initialVariant],
		} = product;

		const productVariant = client.product.helpers.variantForOptions(product, initialVariant) || initialVariant;

		toggleCartFlyout(1000);
		const quantity = 1;
		addVariantToCart(productVariant.shopifyId, quantity);
	};

	if (!product) {
		return null;
	}

	const disabled = product.title === 'Luma' ? 'disabled' : '';

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__container`}>
					<div className={`${ ns }__title`}>
						{product.title}
					</div>
					<div className={`${ ns }__right`}>
						{!disabled && (
							<div className={`${ ns }__price`}>
								<div className={`${ ns }__price-amount`}>
									{product.variants[0].compareAtPrice && <span className={`sale`}>{`$${ product.variants[0].compareAtPrice }`}</span>}
									<span>{`$${ product.variants[0].price }`}</span>
								</div>
							</div>
						)}
						{
							disabled ? (
								<div className={`${ ns }__add-to-cart disabled`}>
									<Button
										onClick={handleAddToCart}
										disabled
									>
										Coming Soon
									</Button>
								</div>
							) : (
								<div className={`${ ns }__add-to-cart`}>
									<Button
										onClick={handleAddToCart}
									>
										Add to cart
									</Button>
								</div>
							)
						}

					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductBanner;
