/* Helper functions */

/**
 * isExternalUrl - used to check for external links
*/

export const isExternalUrl = url => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};

export const setMetaImage = (featuredImage, seo) => {
	let metaImage = null;
	if (seo.metaImage) {
		metaImage = seo.metaImage.sourceUrl;
	} else if (featuredImage) {
		metaImage = featuredImage.sourceUrl;
	}

	return metaImage;
};

export const slugify = string => {
	return string.toString().toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w\\-]+/g, '') // Remove all non-word chars
		.replace(/\\-\\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};
