import React, { useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';

import StoreContext from '../../context/StoreContext';
import SingleProduct from '../SingleProduct';
import Link from '../Link';

const nsBase = 'component';
const ns = `${ nsBase }-nav-dropdown`;

const NavDropdown = ({showNavDropdown, setShowNavDropdown}) => {
	const {
		products,
	} = useContext(StoreContext);

	const wrapperRef = useRef(null);

	useEffect(() => {
		const handleClickOutsideOfDropdown = event => {
			const { target } = event;
			// Checks if element clicked was the dropdown toggle
			const toggleClicked = target.dataset.toggleDropdown;
			// Checks to see if Anchor tag was clicked even (through the event bubbling process)
			const propagationPath = event.path;
			const linkClicked = propagationPath.some(element => {
				return element.nodeName === 'A';
			});

			if ((wrapperRef.current
				&& !wrapperRef.current.contains(target)
				&& !toggleClicked)
				|| linkClicked
			) {
				setShowNavDropdown(false);
			}
		};
		document.addEventListener('click', handleClickOutsideOfDropdown);

		return () => {
			document.removeEventListener('click', handleClickOutsideOfDropdown);
		};
		// eslint-disable-next-line
	}, [wrapperRef]);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`active`]: showNavDropdown,
	});

	return (
		<div ref={wrapperRef} className={`${ rootClassnames }`}>
			<div
				className={`${ ns }__container container-fluid`}
			>
				<div className={`${ ns }__text-container`}>
					<h4 className={`${ ns }__text-container--heading`}>Meet Luma.</h4>
					<div className={`${ ns }__text-container--description`}>
						<div className={`${ ns }__text-container--description-item`}>
							Kills 99.9%* of germs, bacteria and viruses.
						</div>
						<div className={`${ ns }__text-container--description-item`}>
							Laboratory tested and certified.
						</div>
						<div className={`${ ns }__text-container--description-item`}>
							Chemical-free, ozone-free and eco-friendly.
						</div>
						<div className={`${ ns }__text-container--description-item`}>
							360 degree object disinfection
						</div>
						<div className={`${ ns }__text-container--description-item`}>
							Fast cleaning cycles
						</div>
						<div className={`${ ns }__text-container--description-item`}>
							Proprietary UV-C reactor
						</div>
						<div className={`${ ns }__text-container--description-item`}>
							2,210 cubic inches of capacity
						</div>
					</div>
				</div>
				<div className={`${ ns }__product-container`}>
					{ products
						? (
							<>
								<Link className={`${ ns }__single-product`} to={`/product/${ products.lumaPro.slug }/`} title={products.lumaPro.title}>
									<SingleProduct
										title={`Luma Pro`}
										subtitle={products.lumaPro.subtitle}
										image={products.lumaPro.imageTilted}
										infoSnippet={products.lumaPro.infoSnippet}
									/>
								</Link>
								<Link className={`${ ns }__single-product`} to={`/product/${ products.lumaProPlus.slug }/`} title={products.lumaProPlus.title}>
									<SingleProduct
										title={products.lumaProPlus.title}
										subtitle={products.lumaProPlus.subtitle}
										image={products.lumaProPlus.imageTilted}
										infoSnippet={products.lumaProPlus.infoSnippet}
									/>
								</Link>
							</>
						)
						: <p>No Products found!</p>}
				</div>
			</div>
		</div>
	);
};

export default NavDropdown;
