import React from 'react';
import classNames from 'classnames';

import Image from '../Image';
import Link from '../Link';
import Button from '../Button';

const ns = 'single-product';

const SingleProduct = ({
	image,
	handle,
	title,
	// subtitle = 'Smart UV-C Sanitizer',
	overlayColor: backgroundColor = '#F1F2F2',
	price,
	comingSoon,
	salePrice,
	ctaButton,
	infoSnippet,
	infoInOverlay = false,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const renderInfoSnippet = () => {
		return (
			<ul className={`${ ns }__description`}>
				{/* eslint-disable-next-line */}
				<li className={`${ ns }__description-item`}>
					<span className={`${ ns } bold`}>{ infoSnippet.cleanCycle }</span>
					{' '}
					Clean Cycle
				</li>
				<li className={`${ ns }__description-item`}>
					<span className={`${ ns } bold`}>{ infoSnippet.floor }</span>
					{' '}
					Floor
				</li>
				<li className={`${ ns }__description-item`}>
					<span className={`${ ns } bold`}>{ infoSnippet.warranty }</span>
					{' '}
					Warranty
				</li>
			</ul>
		);
	};

	return (
		<div className={`${ rootClassnames }`}>
			<div className={`${ ns }__title color--blue-midnight h5`}>
				{
					handle
						? <Link to={`/product/${ handle }/`}>{title}</Link>
						: title
				}
			</div>
			{
				// subtitle && <div className={`${ ns }__subtitle h6`}>{ subtitle }</div>
			}
			{
				price && (
					// eslint-disable-next-line
					comingSoon ? (
						<p className={`${ ns }__price sale`}>
							{` Coming Soon `}
						</p>
					) : (
						salePrice ? (
							<p className={`${ ns }__price sale`}>
								<span className={`${ ns }__price--sale`}>
									{`$${ salePrice }`}
								</span>
								{price}
							</p>
						) : (
							<p className={`${ ns }__price`}>
								{price}
							</p>
						)
					)
				)
			}
			{image && (
				<div className={`${ ns }__img-overlay-container`}>
					<div className={`${ ns }__img-overlay`} style={{ backgroundColor }} />
					<div className={`${ ns }__img-container`}>
						<Image className={`${ ns }__img`} {...image} />
					</div>
					<div className={`${ ns }__img-overlay-content`}>
						{
							infoInOverlay && infoSnippet && renderInfoSnippet()
						}
						{
							ctaButton && <Button to={`/product/${ handle }/`}>Learn More</Button>
						}
					</div>
				</div>
			)}
			{
				!infoInOverlay && infoSnippet && renderInfoSnippet(true)
			}
		</div>
	);
};

export default SingleProduct;
