import { useState, useEffect } from 'react';
/* eslint-disable import/prefer-default-export */
/* Custom Hooks */

export const useScrollDirection = ({
	initialDirection,
	thresholdPixels,
	off,
} = {}) => {
	const SCROLL_UP = 'up';
	const SCROLL_DOWN = 'down';
	const [scrollDir, setScrollDir] = useState(initialDirection);

	useEffect(() => {
		const threshold = thresholdPixels || 150;
		let lastScrollY = window.pageYOffset;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
			// We haven't exceeded the threshold
				ticking = false;
				return;
			}

			setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		/**
		 * Bind the scroll handler if `off` is set to false.
		 * If `off` is set to true reset the scroll direction.
		 */
		!off
			? window.addEventListener('scroll', onScroll)
			: setScrollDir(initialDirection);

		return () => { return window.removeEventListener('scroll', onScroll); };
	}, [initialDirection, thresholdPixels, off]);

	return scrollDir;
};

export const useOnScreen = (ref, rootMargin = '0px') => {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
			// Update our state when observer callback fires
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin,
			}
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => {
			// eslint-disable-next-line
			observer.unobserve(ref.current);
		};
		// eslint-disable-next-line
	}, []);

	return isIntersecting;
};
